import React, { useCallback, useState, useEffect } from 'react';
import { JsonEditor as Editor } from 'jsoneditor-react';

import { Input, message, Modal, Tabs } from 'antd';

import { IEditableCellProps } from './Types';

import { JSON_ERROR } from '../../constants/JSONerror';

const { TabPane } = Tabs;
const { TextArea } = Input;

const ReportsEditor: React.FC<IEditableCellProps> = ({
  cell: { value: initialValue },
  row: { index },
  column: { id },
  updateMyData,
}): JSX.Element => {
  const [activeTab, setActiveTab] = useState<string>('1');
  const [value, setValue] = useState<string>(initialValue);
  const [isDisabled, setDisabled] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (isDisabled) {
      message.error(JSON_ERROR);
    }
  }, [isDisabled]);

  const handleCancel = useCallback(() => {
    setValue(initialValue);
    setModalOpen(false);
  }, [initialValue]);

  const handleOk = useCallback(() => {
    updateMyData(index, id, value);
    setModalOpen(false);
  }, [id, index, value, updateMyData]);

  const handleChange = useCallback(e => {
    try {
      setValue(e.target.value);
      JSON.parse(e.target.value || '{}');
      setDisabled(false);
    } catch {
      setDisabled(true);
    }
  }, []);

  return (
    <>
      <Modal
        visible={isModalOpen}
        okButtonProps={{ type: isDisabled ? 'dashed' : 'primary' }}
        onOk={isDisabled ? () => message.error(JSON_ERROR) : handleOk}
        onCancel={handleCancel}
      >
        <Tabs defaultActiveKey="1" activeKey={activeTab} onChange={key => setActiveTab(key)}>
          <TabPane tab="Text" key="1">
            <TextArea
              rows={4}
              onClick={() => setModalOpen(true)}
              value={activeTab === '1' ? value : ''}
              onChange={activeTab === '1' ? handleChange : undefined}
            />
          </TabPane>
          <TabPane disabled={isDisabled} tab="Editor" key="2">
            <Editor
              value={activeTab === '2' ? JSON.parse(value || '{}') : {}}
              onChange={activeTab === '2' ? value => setValue(JSON.stringify(value)) : undefined}
            />
          </TabPane>
        </Tabs>
      </Modal>
      <Input onClick={() => setModalOpen(true)} value={value} onChange={e => setValue(e.target.value)} />
    </>
  );
};

export default ReportsEditor;
