import React, { useCallback, useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';

import { Button, Select } from 'antd';

import { IAdminReportsProps } from './Types';
import { IDocumentData } from '../../types/Common';

import './Reports.scss';

const { Option } = Select;

const AdminReports: React.FC<IAdminReportsProps> = ({ token }): JSX.Element => {
  const db = firebase.firestore();
  const [isAdmin, setAdmin] = useState<boolean>(false);
  const [reports, setReports] = useState<IDocumentData[]>([]);
  const [value, setValue] = useState<string>('');
  const [url, setUrl] = useState<string>('');

  useEffect(() => {
    firebase
      .auth()
      .currentUser?.getIdTokenResult()
      .then(id => {
        if (id.claims?.admin) {
          setAdmin(true);
        }
      })
      .catch(err => console.error(err));
  }, []);

  useEffect(() => {
    if (isAdmin) {
      db.collection('reports')
        .orderBy('title', 'asc')
        .onSnapshot(res => {
          setValue(res.docs[0].id);
          const reportsData = res.docs.map(report => ({ id: report.id, ...report.data() }));
          setReports(reportsData);
        });
    }
  }, [db, isAdmin, setReports]);

  const handleUpdate = useCallback(() => {
    if (value) {
      fetch('/api/dashboard', {
        method: 'POST',
        headers: {
          authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: value }),
      })
        .then(res => res.text())
        .then(url => {
          setUrl(url);
        })
        .catch(err => console.error(err));
    }
  }, [token, value]);

  useEffect(() => {
    if (value) {
      handleUpdate();
    }
  }, [handleUpdate, value]);

  return (
    <>
      <div style={{ display: 'flex' }}>
        {!!reports.length && (
          <>
            <Select className="select-report" value={value} onChange={value => setValue(value)}>
              {reports.map(({ id, title }) => (
                <Option value={id} key={id}>
                  {title}
                </Option>
              ))}
            </Select>
            <Button style={{ marginLeft: 30 }} onClick={handleUpdate}>
              Обновить
            </Button>
          </>
        )}
      </div>
      {url && <iframe title="report" src={url} width="100%" height="850" frameBorder="0"></iframe>}
    </>
  );
};

export default AdminReports;
