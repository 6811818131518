import React, { useEffect } from 'react';
import { auth } from 'firebase/app';
import 'firebase/auth';

import { IRecaptchaProps } from './Types';

const Recaptcha: React.FC<IRecaptchaProps> = ({ elementID = 'recaptcha-container', setRecaptcha }): JSX.Element => {
  useEffect(() => {
    const recaptchaVerifier = new auth.RecaptchaVerifier(elementID);
    recaptchaVerifier.render();
    setRecaptcha(recaptchaVerifier);
  }, [elementID, setRecaptcha]);

  return <div id={elementID} />;
};

export default Recaptcha;
