import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import Recaptcha from './Recaptcha';
import UserPanel from '../UserPanel/UserPanel';

import { Button, Input, message, Modal } from 'antd';

import { ConfirmationResult, RecaptchaVerifier } from '@firebase/auth-types';

import './Auth.scss';

const Auth: React.FC = (): JSX.Element => {
  const [code, setCode] = useState<string>('');
  const [confirmationResult, setConfirmationResult] = useState<ConfirmationResult>();
  const [phone, setPhone] = useState<string>('');
  const [phoneValue, setPhoneValue] = useState<string>('');
  const [token, setToken] = useState<string>('');
  const [recaptcha, setRecaptcha] = useState<RecaptchaVerifier>();
  const [uid, setUid] = useState<string>('');

  const history = useHistory();

  const db = firebase.firestore();

  useEffect(() => {
    firebase
      .auth()
      .currentUser?.getIdTokenResult()
      .then(id => {
        if (id.claims?.admin) {
          history.push('/admin');
        }
      })
      .catch(err => console.error(err));
    firebase
      .auth()
      .currentUser?.getIdToken()
      .then(token => setToken(token))
      .catch(err => console.error(err));
    if (phone && uid) {
      db.collection('users')
        .get()
        .then(res => {
          const isUser = res.docs.find(doc => {
            return doc.data().phone === phone;
          });
          if (!isUser) {
            db.collection('users')
              .doc(uid)
              .set({ phone, isAdmin: false, active: true });
          }
        });
    }
  }, [db, history, phone, uid]);

  const confirm = useCallback(async () => {
    try {
      const result = await confirmationResult?.confirm(code);
      setUid(result?.user?.uid as string);
      setPhone(result?.user?.phoneNumber as string);
    } catch (err) {
      message.error('Code is invalid');
    }
  }, [code, confirmationResult]);

  const sendSMS = useCallback(() => {
    firebase
      .auth()
      .signInWithPhoneNumber(`+380${phoneValue}`, recaptcha as RecaptchaVerifier)
      .then(confirmationResult => {
        setConfirmationResult(confirmationResult);
      })
      .catch(err => {
        message.error(err.message);
      });
  }, [phoneValue, recaptcha]);

  const handleKeyPress = useCallback(
    e => {
      if (e.key === 'Enter') {
        if (confirmationResult) {
          confirm();
        } else {
          sendSMS();
        }
      }
    },
    [confirm, confirmationResult, sendSMS],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <>
      <Modal closable={false} footer={null} visible={!phone}>
        <>
          {confirmationResult ? (
            <>
              <Input autoFocus onChange={e => setCode(e.target.value)} value={code} />
              <Button className="send-button" onClick={confirm}>
                Confirm
              </Button>
            </>
          ) : (
            <>
              <Input
                autoFocus
                addonBefore="+380"
                onChange={e => setPhoneValue(e.target.value)}
                pattern="\d{9}"
                type="tel"
                value={phoneValue}
                className="phone-input"
              />
              <Recaptcha setRecaptcha={setRecaptcha} />
              <Button className="send-button" onClick={sendSMS}>
                Send
              </Button>
            </>
          )}
        </>
      </Modal>
      {phone && token && uid && <UserPanel phone={phone} token={token} uid={uid} />}
    </>
  );
};

export default Auth;
