import React, { useCallback, useEffect, useState, useMemo } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import AddActive from './AddActive';
import AddAdmin from './AddAdmin';
import EditableCell from './EditableCell';
import Grid from './Grid';
import ReportsSelect from './ReportsSelect';
import SMS from './SMS';
import TextAreaCell from './TextAreaCell';

import { IDocumentData } from '../../types/Common';

import { Button } from 'antd';

const UsersTable: React.FC = (): JSX.Element => {
  const [activeVisible, setActiveVisible] = useState<boolean>(true);
  const [data, setData] = useState<IDocumentData[]>();
  const [token, setToken] = useState<string>('');
  const [userLoginData, setUserLoginData] = useState<IDocumentData>();

  const db = firebase.firestore();

  useEffect(() => {
    firebase
      .auth()
      .currentUser?.getIdToken()
      .then(token => setToken(token))
      .catch(err => console.error(err));
  }, []);

  useEffect(() => {
    if (token) {
      fetch('/api/getUserLogin', {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
        .then(res => res.json())
        .then(result => setUserLoginData(result))
        .catch(err => console.error(err));
    }
  }, [token]);

  useEffect(() => {
    if (userLoginData) {
      const unsubscribe = db.collection('users').onSnapshot(docs => {
        const grid: IDocumentData[] = [];
        docs.forEach(doc => grid.push({ id: doc.id, ...doc.data(), ...userLoginData[doc.id] }), grid);
        const users = grid.filter(({ active }) => active === activeVisible);
        setData(users);
      });

      return () => {
        unsubscribe();
      };
    }
  }, [activeVisible, db, userLoginData]);

  const columns = useMemo(
    () => [
      {
        Header: 'Телефон',
        accessor: 'phone',
      },
      {
        Header: 'Имя',
        accessor: 'firstName',
        Cell: EditableCell,
      },
      {
        Header: 'Фамилия',
        accessor: 'lastName',
        Cell: EditableCell,
      },
      {
        Header: 'Почта',
        accessor: 'email',
        Cell: EditableCell,
      },
      {
        Header: 'Отчеты',
        accessor: 'reports',
        Cell: ReportsSelect,
      },
      {
        Header: 'Админ',
        accessor: 'admin',
        Cell: AddAdmin,
      },
      {
        Header: 'Активный',
        accessor: 'active',
        Cell: AddActive,
      },
      {
        Header: 'SMS',
        accessor: 'sms',
        Cell: SMS,
      },
      {
        Header: 'Создание',
        accessor: 'creationTime',
      },
      {
        Header: 'Логин',
        accessor: 'lastSignInTime',
      },
      {
        Header: 'Комментарий',
        accessor: 'comment',
        Cell: TextAreaCell,
      },
    ],
    [],
  );

  const updateMyData = useCallback(
    (rowIndex: number, columnId: string, value: string) => {
      setData(old =>
        old?.map((row, index) => {
          if (
            index === rowIndex &&
            (value || columnId === 'reports' || columnId === 'admin' || columnId === 'active')
          ) {
            const user: IDocumentData = {
              ...old[rowIndex],
              [columnId]: value,
            };
            const { id, ...newUser } = user;
            db.collection('users')
              .doc(id)
              .update(newUser);
            return {
              ...old[rowIndex],
              [columnId]: value,
            };
          }
          return row;
        }),
      );
    },
    [db],
  );

  return (
    <>
      {data && (
        <>
          <Grid columns={columns} data={data} updateMyData={updateMyData} />
          <Button onClick={() => setActiveVisible(!activeVisible)}>{activeVisible ? '' : 'Не'} Активные</Button>
        </>
      )}
    </>
  );
};

export default UsersTable;
