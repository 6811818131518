import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import firebase from 'firebase/app';
import ReactDOM from 'react-dom';

import Admin from './pages/Admin';
import App from './App';
import NotFoundPage from './pages/404';
import PrimaryPage from './pages/PrimaryPage';

import './index.scss';
import 'antd/dist/antd.css';

import * as serviceWorker from './serviceWorker';

firebase.initializeApp({
  apiKey: 'AIzaSyCjiF6L9hsKa_OfjI_DOmuF0WqgH2YTt3Q',
  authDomain: 'otk-partner-dashboard.firebaseapp.com',
  databaseURL: 'https://otk-partner-dashboard.firebaseio.com',
  messagingSenderId: '846018493013',
  projectId: 'otk-partner-dashboard',
  storageBucket: 'otk-partner-dashboard.appspot.com',
});

ReactDOM.render(
  <BrowserRouter>
    <App>
      <Switch>
        <Route path="/" exact component={PrimaryPage} />
        <Route path="/admin" exact component={Admin} />
        <Route component={NotFoundPage} />
      </Switch>
    </App>
  </BrowserRouter>,
  document.getElementById('root'),
);
serviceWorker.unregister();
