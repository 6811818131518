import React from 'react';

import { Tabs } from 'antd';

import Reports from '../Reports/Reports';
import UserForm from '../UserForm/UserForm';

import { IUserPanelProps } from './Types';

const { TabPane } = Tabs;

const UserPanel: React.FC<IUserPanelProps> = ({ phone, token, uid }): JSX.Element => (
  <Tabs defaultActiveKey="1">
    <TabPane tab="Отчеты" key="1">
      <Reports token={token} uid={uid} />
    </TabPane>
    <TabPane tab="Отправить сообщение" key="2">
      <UserForm phone={phone} uid={uid} />
    </TabPane>
  </Tabs>
);

export default UserPanel;
