import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { Button, Form, Input, message } from 'antd';

import { IDocumentData } from '../../types/Common';
import { IUserFormProps } from './Types';

import { FORM_ERRORS } from '../../constants/FormErrors';

const { emailError, minError, requiredError } = FORM_ERRORS;

const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 32 },
    sm: { span: 12 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 2,
    },
  },
};

const validationSchema = Yup.object({
  email: Yup.string()
    .email(emailError)
    .required(requiredError),
  firstName: Yup.string()
    .min(2, minError)
    .required(requiredError),
  lastName: Yup.string()
    .min(2, minError)
    .required(requiredError),
});

const UserForm: React.FC<IUserFormProps> = ({ phone, uid }): JSX.Element => {
  const [isDisabled, setDisabled] = useState({ email: false, firstName: false, lastName: false });
  const { errors, handleSubmit, handleChange, setFieldValue, values } = useFormik({
    initialValues: {
      comment: '',
      email: '',
      firstName: '',
      lastName: '',
    },
    validateOnChange: false,
    validationSchema,
    onSubmit(values, { resetForm }) {
      const data = { ...values, phone, uid };

      fetch('/api/sendMail', {
        method: 'POST',
        body: JSON.stringify(data),
      })
        .then(() => {
          message.success('Успешно отправлено');
          resetForm();
        })
        .catch(err => console.error(err));
    },
  });

  const { comment, email, firstName, lastName } = values;

  const db = firebase.firestore();

  useEffect(() => {
    const unsubscribe = db
      .collection('users')
      .doc(uid)
      .onSnapshot(user => {
        const { comment, ...fields } = values;
        Object.keys(fields).forEach(field => {
          if ((Object.keys(user.data() as IDocumentData) || []).includes(field) && user.data()?.[field]) {
            setDisabled(isDisabled => ({ ...isDisabled, [field]: true }));
            setFieldValue(field, user.data()?.[field]);
          }
        });
      });

    return () => {
      unsubscribe();
    };
  }, [db, setFieldValue, values, uid]);

  return (
    <Form {...formItemLayout} onSubmit={handleSubmit}>
      <Form.Item
        label="Имя"
        validateStatus={errors.firstName ? 'error' : ''}
        help={errors.firstName ? errors.firstName : ''}
      >
        <Input
          placeholder="Имя"
          disabled={isDisabled.firstName}
          onChange={handleChange}
          id="firstName"
          value={firstName}
        />
      </Form.Item>
      <Form.Item
        label="Фамилия"
        validateStatus={errors.lastName ? 'error' : ''}
        help={errors.lastName ? errors.lastName : ''}
      >
        <Input
          placeholder="Фамилия"
          disabled={isDisabled.lastName}
          onChange={handleChange}
          id="lastName"
          value={lastName}
        />
      </Form.Item>
      <Form.Item label="Почта" validateStatus={errors.email ? 'error' : ''} help={errors.email ? errors.email : ''}>
        <Input placeholder="Почта" disabled={isDisabled.email} onChange={handleChange} id="email" value={email} />
      </Form.Item>
      <Form.Item
        label="Комментарий"
        validateStatus={errors.comment ? 'error' : ''}
        help={errors.comment ? errors.comment : ''}
      >
        <TextArea placeholder="Комментарий" onChange={handleChange} id="comment" value={comment} />
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          Отправить
        </Button>
      </Form.Item>
    </Form>
  );
};

export default UserForm;
