import React, { useEffect, useCallback, useMemo, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';

import { Button } from 'antd';

import DashboardSelect from './DashboardSelect';
import DeleteButton from './DeleteButton';
import EditableCell from './EditableCell';
import Grid from './Grid';
import ReportsEditor from './ReportsEditor';

import { IDocumentData } from '../../types/Common';

const ReportsTable: React.FC = (): JSX.Element => {
  const [reports, setReports] = useState<IDocumentData[]>();
  const columns = useMemo(
    () => [
      {
        Header: 'Название',
        accessor: 'title',
        Cell: EditableCell,
      },
      {
        Header: 'Тип',
        accessor: 'type',
        Cell: DashboardSelect,
      },
      {
        Header: 'Dashboard',
        accessor: 'dashboard',
        Cell: EditableCell,
      },
      {
        Header: 'Параметры',
        accessor: 'params',
        Cell: ReportsEditor,
      },
      {
        Header: 'Удалить',
        accessor: 'delete',
        Cell: ({ row }) => <DeleteButton reports={reports as IDocumentData[]} row={row} setReports={setReports} />,
      },
    ],
    [reports],
  );

  const db = firebase.firestore();

  useEffect(() => {
    const unsubscribe = db
      .collection('reports')
      .orderBy('title', 'asc')
      .onSnapshot(docs => {
        const grid: IDocumentData[] = [];
        docs.forEach(doc => grid.push({ id: doc.id, ...doc.data() }), grid);
        setReports(grid);
      });

    return () => {
      unsubscribe();
    };
  }, [db]);

  const updateMyData = useCallback(
    (rowIndex: number, columnId: string, value: string) => {
      setReports(old =>
        old?.map((row, index) => {
          if (index === rowIndex && value) {
            const report: IDocumentData = {
              ...old[rowIndex],
              [columnId]: value,
            };
            const { id, ...newReport } = report;
            db.collection('reports')
              .doc(id)
              .update(newReport);
            return {
              ...old[rowIndex],
              [columnId]: value,
            };
          }
          return row;
        }),
      );
    },
    [db, setReports],
  );

  const addReport = useCallback(() => {
    if (reports) {
      const report = reports[reports.length - 1];
      const { id, ...newReport } = report;
      db.collection('reports').add(newReport);
    }
  }, [db, reports]);

  return (
    <>
      {reports && (
        <>
          <Grid columns={columns} data={reports} updateMyData={updateMyData} />
          <Button onClick={addReport}>Добавить отчет</Button>
        </>
      )}
    </>
  );
};

export default ReportsTable;
