import React, { useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';

import { Button } from 'antd';

import { IDeleteButtonProps } from './Types';

const DeleteButton: React.FC<IDeleteButtonProps> = ({ row: { original }, setReports }): JSX.Element => {
  const db = firebase.firestore();

  const handleDelete = useCallback(() => {
    setReports(reports => reports?.filter(report => report !== original));
    db.collection('reports')
      .doc(original.id)
      .delete();
  }, [db, original, setReports]);

  return <Button onClick={handleDelete}>Удалить</Button>;
};

export default DeleteButton;
