import React, { useCallback, useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';

import { Button, Select } from 'antd';

import { IDocumentData } from '../../types/Common';
import { IReportsProps } from './Types';

import './Reports.scss';

const { Option } = Select;

const Reports: React.FC<IReportsProps> = ({ token, uid }): JSX.Element => {
  const db = firebase.firestore();
  const [reports, setReports] = useState<IDocumentData[]>([]);
  const [value, setValue] = useState<string>('');
  const [url, setUrl] = useState<string>('');

  useEffect(() => {
    const unsubscribe = db
      .collection('users')
      .doc(uid)
      .onSnapshot(res => {
        const reportsData: string[] = res.data()?.reports.split(',');
        setValue(reportsData[0]);
        reportsData.forEach(report => {
          db.collection('reports')
            .doc(report)
            .onSnapshot(doc => {
              setReports(reports =>
                reports.length ? [...reports, { ...doc.data(), id: report }] : [{ ...doc.data(), id: report }],
              );
            });
        });
      });

    return () => {
      unsubscribe();
    };
  }, [db, uid]);

  const handleUpdate = useCallback(() => {
    if (value) {
      fetch('/api/dashboard', {
        method: 'POST',
        headers: {
          authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: value }),
      })
        .then(res => res.text())
        .then(url => {
          setUrl(url);
        })
        .catch(err => console.error(err));
    }
  }, [token, value]);

  useEffect(() => {
    if (value) {
      handleUpdate();
    }
  }, [handleUpdate, value]);

  return (
    <>
      <div style={{ display: 'flex' }}>
        {!!reports.length ? (
          <>
            <Select className="select-report" value={value} onChange={value => setValue(value)}>
              {reports.map(({ id, title }) => (
                <Option value={id} key={id}>
                  {title}
                </Option>
              ))}
            </Select>
            <Button style={{ marginLeft: 30 }} onClick={handleUpdate}>
              Обновить
            </Button>
          </>
        ) : (
          <p className="reports-empty">У вас нет доступных отчетов</p>
        )}
      </div>
      {url && <iframe title="report" src={url} width="100%" height="850" frameBorder="0"></iframe>}
    </>
  );
};

export default Reports;
