import React, { useCallback, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';

import { Button, Input, message, Modal } from 'antd';

import { IEditableCellProps } from './Types';

const { TextArea } = Input;

const SMS: React.FC<IEditableCellProps> = ({ row: { values } }): JSX.Element => {
  const [isModalOpen, openModal] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');

  const { phone } = values;

  const handleCancel = useCallback(() => {
    openModal(false);
    setValue('');
  }, []);

  const sendSMS = useCallback(
    token => {
      fetch('/api/sendSMS', {
        method: 'post',
        body: JSON.stringify({ phone, text: value }),
        headers: { 'Content-Type': 'application/json', authorization: `Bearer ${token}` },
      })
        .then(res => res.json())
        .then(json => {
          openModal(false);
          setValue('');
          if (json.response_code === 801) {
            message.success('Сообщение отправлено');
          } else {
            message.error('Сообщение не отправлено');
          }
        })
        .catch(() => {
          openModal(false);
          setValue('');
          message.error('Сообщение не отправлено');
        });
    },
    [phone, value],
  );

  const handleSend = useCallback(() => {
    firebase
      .auth()
      .currentUser?.getIdToken()
      .then(token => sendSMS(token))
      .catch(err => console.error(err));
  }, [sendSMS]);

  return (
    <>
      <Modal
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={handleCancel}
        title={`Отправить сообщение ${phone}`}
        visible={isModalOpen}
      >
        <TextArea style={{ marginTop: 20 }} rows={4} value={value} onChange={e => setValue(e.target.value)} />
        <Button onClick={handleSend} style={{ marginTop: 20 }} type="primary">
          Отправить
        </Button>
      </Modal>
      <Button onClick={() => openModal(true)}>SMS</Button>
    </>
  );
};

export default SMS;
