import React, { useCallback, useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';

import { Select } from 'antd';

import { IDocumentData } from '../../types/Common';
import { IEditableCellProps } from './Types';

import './ReportsSelect.scss';

const { Option } = Select;

const ReportSelect: React.FC<IEditableCellProps> = ({
  row: { index, values },
  column: { id },
  updateMyData,
}): JSX.Element => {
  const [options, setOptions] = useState<IDocumentData>();

  const db = firebase.firestore();

  useEffect(() => {
    const unsubscribe = db.collection('reports').onSnapshot(docs => {
      const grid: IDocumentData[] = [];
      docs.forEach(doc => grid.push({ id: doc.id, ...doc.data() }), grid);
      setOptions(grid);
    });

    return () => {
      unsubscribe();
    };
  }, [db]);

  const handleChange = useCallback(
    value => {
      const reports = value.join(',');
      updateMyData(index, id, reports);
    },
    [id, index, updateMyData],
  );

  return (
    <Select
      mode="multiple"
      value={typeof values?.reports === 'string' && values?.reports ? values?.reports?.split(',') : []}
      style={{ width: 170 }}
      onChange={handleChange}
    >
      {options &&
        options.map(({ id, title }) => (
          <Option key={id} value={id}>
            {title}
          </Option>
        ))}
    </Select>
  );
};

export default ReportSelect;
