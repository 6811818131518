import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const NotFoundPage: React.FC = (): JSX.Element => {
  const history = useHistory();
  useEffect(() => {
    history.push('/');
  }, [history]);
  return <></>;
};
export default NotFoundPage;
