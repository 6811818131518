import React, { useCallback, useState } from 'react';

import { Select } from 'antd';

import { IEditableCellProps } from './Types';

import { DASHBOARD_TYPES } from '../../constants/DashboardsTypes';

import './ReportsSelect.scss';

const { Option } = Select;

const DashboardSelect: React.FC<IEditableCellProps> = ({
  cell: { value: initialValue },
  row: { index, values },
  column: { id },
  updateMyData,
}): JSX.Element => {
  const [value, setValue] = useState<string>(initialValue);

  const handleChange = useCallback(
    value => {
      setValue(value.toString());
      updateMyData(index, id, value);
    },
    [id, index, updateMyData],
  );

  return (
    <Select
      defaultValue={values?.reports?.split(',') || []}
      style={{ width: '300px' }}
      onChange={handleChange}
      value={value}
    >
      {DASHBOARD_TYPES.map(dashboard => (
        <Option key={dashboard} value={dashboard}>
          {dashboard}
        </Option>
      ))}
    </Select>
  );
};

export default DashboardSelect;
