import React, { useEffect, useState } from 'react';

import { Input } from 'antd';

import { IEditableCellProps } from './Types';

const { TextArea } = Input;

const TextAreaCell: React.FC<IEditableCellProps> = ({
  cell: { value: initialValue },
  row: { index },
  column: { id },
  updateMyData,
}): JSX.Element => {
  const [value, setValue] = useState<string>(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <TextArea
      rows={2}
      value={value}
      onChange={e => setValue(e.target.value)}
      onBlur={() => updateMyData(index, id, value)}
    />
  );
};

export default TextAreaCell;
