import React from 'react';

import { Button } from 'antd';

import { IEditableCellProps } from './Types';

const AddActive: React.FC<IEditableCellProps> = ({
  cell: { value },
  row: { index },
  column: { id },
  updateMyData,
}): JSX.Element => <Button onClick={() => updateMyData(index, id, !value)}>{value ? 'Да' : 'Нет'}</Button>;

export default AddActive;
