import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/auth';

import { Tabs } from 'antd';

import AdminReports from '../components/Reports/AdminReports';
import ReportsTable from '../components/Table/ReportsTable';
import UsersTable from '../components/Table/UsersTable';

const { TabPane } = Tabs;

const Admin: React.FC = (): JSX.Element => {
  const [token, setToken] = useState<string>('');

  const history = useHistory();

  if (!firebase.auth().currentUser) {
    history.push('/');
  }

  useEffect(() => {
    const user = firebase.auth().currentUser;
    user
      ?.getIdToken()
      .then(token => setToken(token))
      .catch(err => console.error(err));
  }, []);

  return (
    <Tabs className="tabs" defaultActiveKey="1">
      <TabPane tab="Пользователи" key="1">
        <UsersTable />
      </TabPane>
      <TabPane tab="Отчеты" key="2">
        <ReportsTable />
      </TabPane>
      <TabPane tab="Готовые отчеты" key="3">
        {token && <AdminReports token={token} />}
      </TabPane>
    </Tabs>
  );
};

export default Admin;
